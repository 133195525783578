/* Стили для контейнера загрузки */
.loading-bar {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 0px;
}

/* Стили для анимированных точек */
.loader-dots {
    display: inline-block;
    font-size: 54px;
    line-height: 1;
    color: rgb(31, 130, 151); /* Цвет точек */
}

.loader-dots span {
    display: inline-block;
    animation: bounce 1.4s infinite ease-in-out;
}

.loader-dots span:nth-child(1) {
    animation-delay: -0.32s;
}

.loader-dots span:nth-child(2) {
    animation-delay: -0.16s;
}

/* Анимация "прыгающих точек" */
@keyframes bounce {
    0%, 80%, 100% {
        transform: scale(0);
    }
    40% {
        transform: scale(1);
    }
}