@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@400;500;700&display=swap');

.home-page {
    margin-top: 85px;
}

.other-pages {
    margin-top: 0;
}

/* Глобальные стили */
body {
    font-family: 'Manrope', Arial, sans-serif;
    margin: 0;
    padding: 0;
    background-color: #F4F4F4;
    color: #333333;
    line-height: 1;
}

.App {
    text-align: center;
    max-width: 1200px;
    margin: 0 auto;
    padding: 2px;
}

/* Кнопка для загрузки */
.load-more-button {
    margin: 20px auto;
    padding: 12px 24px;
    background-color: #1F8297;
    color: #fff;
    border: none;
    border-radius: 30px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
    display: inline-block;
}

.load-more-button:hover {
    background-color: #1A6F82;
    transform: scale(1.05);
}

.load-more-button:disabled {
    background-color: #adb5bd;
    cursor: not-allowed;
}

/* такие же кнопки как в футере но в хедре */
.action-buttons {
    display: flex;
    gap: 10px;
    justify-content: normal;
    align-items: center;
    align-content: stretch;
    flex-wrap: wrap;
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
}

/* Футер */
.App-footer {
    display: flex;
    justify-content: center;
    gap: 20px;
    background-color: #1F8297;
    position: sticky;
    bottom: 0;
    z-index: 10;
}

.footer-button {
    padding: 12px 24px;
    background-color: #FBC65D;
    color: white;
    border: none;
    border-radius: 30px;
    font-size: 16px;
    text-transform: uppercase;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
}

.footer-button:hover {
    background-color: #E0B354;
    transform: scale(1.05);
}

/* Для экранов с шириной менее 768px */
@media (max-width: 768px) {
    img {
        max-width: 100%;
        height: auto;
    }
}

/* Для экранов с шириной 768px и больше */
@media (min-width: 768px) {
    img {
        max-width: 100%;
        height: auto;
    }
}

.App-footer {
    display: flex;
    justify-content: space-around;
    align-items: center;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #f8f9fa;
    padding-bottom: 10px;
    padding-top: 5px;
    box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
}

.footer-link {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    color: #333333;
    font-size: 12px;
}

.footer-link span {
    margin-top: 5px;
    font-size: 10px;
}

.App-header-fixed {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #f8f9fa;
    display: flex;
    align-items: center;
    padding: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    z-index: 1000;
}

.search-input {
    flex-grow: 1;
    padding: 8px 10px;
    margin-left: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 14px;
    outline: none;
}

.search-icon {
    color: #555;
}

/* Контейнер для всей страницы */
.dashboard-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
    font-family: Arial, sans-serif;
}

/* Блок информации о пользователе */
.user-info {
    text-align: center;
    margin-bottom: 5px;
}

.user-info h4 {
    margin: 0;
    font-size: 1rem;
    color: #333333;
}

.user-info p {
    margin: 5px 0 0;
    font-size: 0.7rem;
    color: #757575;
}

.dashboard-button {
    display: inline-block;
    padding: 5px 10px;
    font-size: 0.8rem;
    color: #fff;
    background-color: #1F8297;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s;
}

.dashboard-button:hover {
    background-color: #1A6F82;
}

/* Адаптивность для мобильных устройств */
@media (max-width: 600px) {
    .action-buttons {
        flex-direction: row;
        gap: 10px;
    }

    .dashboard-button {
        text-align: center;
    }
}

.popup-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.popup {
    background: white;
    padding: 20px;
    width: 70%;
    max-width: 400px;
    border-radius: 10px;
    text-align: center;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25);
}

.popup h4 {
    margin: 0 0 10px;
    font-size: 1.1em;
}

.popup p {
    margin: 0 0 20px;
    font-size: 1em;
    color: #757575;
}

.close-popup-btn {
    background: #1F8297;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.close-popup-btn:hover {
    background: #1A6F82;
}

/* Базовые стили для всех кнопок */
.footer-link {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    color: #333333;
    font-size: 12px;
    padding: 10px;
    border-radius: 8px; /* Мягкое закругление */
    transition: background-color 0.3s ease, color 0.3s ease;
}

.footer-link span {
    margin-top: 5px;
    font-size: 10px;
}

/* Стили для активной кнопки */
.footer-link.active {
    background-color: #1F8297; /* Цвет фона */
    color: #fff; /* Цвет текста */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Легкая тень */
}

.footer-link.active span {
    color: #fff; /* Цвет текста под иконкой */
}

/* Наведение на неактивные кнопки */
.footer-link:not(.active):hover {
    background-color: #f0f0f0; /* Легкий серый фон при наведении */
    color: #1F8297; /* Цвет текста при наведении */
}

.footer-link:not(.active):hover span {
    color: #1F8297; /* Цвет текста под иконкой при наведении */
}

.popup-container .scam-link {
  color: #1F8297; /* Приглушенный teal */
  text-decoration: underline;
  cursor: pointer;
}